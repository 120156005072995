import { Box, IconButton, List, ListItem, ListItemButton } from "@mui/material";
import PL from "../../assets/PL_Trans.png";
import EN from "../../assets/EN_Trans.png";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { useTranslation } from "react-i18next";

type Props = {
  setDarkMode: (mode: boolean) => void;
  darkMode: boolean;
};

export const ActionButtonsModule = (props: Props) => {
  const [t, i18n] = useTranslation("global");

  const toggleDarkMode = () => {
    props.setDarkMode(!props.darkMode);
  };

  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft:"50px" }}>
      {i18n.language === "pl" ? (
        <ListItemButton
          sx={{ textAlign: "center" }}
          onClick={() => handleChangeLanguage("en")}
        >
          <img
            style={{ width: "40px", height: "30px" }}
            src={EN}
            alt={"en"}
            loading="lazy"
          />
        </ListItemButton>
      ) : (
        <ListItemButton
          sx={{ textAlign: "center" }}
          onClick={() => handleChangeLanguage("pl")}
        >
          <img
            style={{ width: "40px", height: "30px" }}
            src={PL}
            alt={"pl"}
            loading="lazy"
          />
        </ListItemButton>
      )}
      <IconButton sx={{ ml: 1 }} onClick={toggleDarkMode} color="inherit">
        {props.darkMode === true ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Box>
  );
};
