import { Box, Grid, Paper, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import BusinessIcon from "@mui/icons-material/Business";
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";
import blucher from "../../assets/slider/blucher.png";
import geberit from "../../assets/slider/geberit.png";
import gf from "../../assets/slider/gf.png";
import mariof from "../../assets/slider/mariof.png";
import peter from "../../assets/slider/peter.png";
import roxtec from "../../assets/slider/roxtec.png";
import viega from "../../assets/slider/viega.png";

const companyLogosForSlider: string[] = [
  blucher,
  geberit,
  gf,
  mariof,
  peter,
  roxtec,
  viega,
];

export const Contact = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h3" gutterBottom>
          {t("Contact")}
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ textAlign: "center" }}>
        <Grid item xs={12} md={4}>
          <Paper
            variant="elevation"
            elevation={6}
            sx={{ width: "100%", padding: "10px" }}
          >
            <BusinessIcon />
            <Typography variant="h4" gutterBottom>
              {t("Address")}
            </Typography>

            <Typography variant="h5" gutterBottom>
              Aleja Grunwaldzka 487c
            </Typography>

            <Typography variant="h5" gutterBottom>
              80-309 Gdańsk
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            variant="elevation"
            elevation={6}
            sx={{ width: "100%", padding: "10px" }}
          >
            <LocalPhoneIcon />
            <Typography variant="h4" gutterBottom>
              {t("Phone")}
            </Typography>

            <Typography variant="h5" gutterBottom>
              +48 500601240
            </Typography>

            <Typography variant="h5" gutterBottom>
              +48 698125290
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            variant="elevation"
            elevation={6}
            sx={{ width: "100%", padding: "10px" }}
          >
            <EmailIcon />
            <Typography variant="h4" gutterBottom>
              {t("E-mail")}
            </Typography>

            <Typography variant="h5" gutterBottom>
              office@montechserwis.com
            </Typography>
            <Typography variant="h5" gutterBottom>
              montechserwis@gmail.com
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Marquee autoFill={true} speed={80}>
        {companyLogosForSlider.map((logoUrl:string)=>{
          return(
            <img src={logoUrl} height={60} style={{margin:"10px"}}/>
          )
        })}
      </Marquee>
    </>
  );
};
