import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { ImagesCarousel } from "../ImagesCarousel/ImagesCarousel";
import whiteLogo from "../../assets/Logo_MonTech_White.png";
import { AboutUs } from "../AboutUs/AboutUs";
import { ActionButtonsModule } from "./ActionButtonsModule";
import { useTranslation } from "react-i18next";
import { Contact } from "../Contact/Contact";
import { ProjectsCarousel } from "../Projects/ProjectsCarousel";

interface Props {
  window?: () => Window;
  setDarkMode: (mode: boolean) => void;
  darkMode: boolean;
}

type navItemsProps = {
  name: string;
  id: string;
};

const drawerWidth = 240;

export default function DrawerAppBar(props: Props) {
  const [t, i18n] = useTranslation("global");

  const navItems: navItemsProps[] = [
    { name: t("About"), id: "About" },
    { name: t("Our Projects"), id: "Our Projects" },
    { name: t("Contact"), id: "Contact" },
  ];

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box sx={{ background: "#000" }}>
        <img
          style={{ width: "120px", height: "120px" }}
          src={whiteLogo}
          alt={"logo"}
          loading="lazy"
        />
      </Box>

      <Divider />
      <List>
        {navItems.map((item: navItemsProps) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={() => scrollToSection(item.id)}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
        <Divider />
        <ListItem key={"actions"} disablePadding>
          <ActionButtonsModule
            darkMode={props.darkMode}
            setDarkMode={props.setDarkMode}
          />
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav" sx={{ background: "#000" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", md: "block" } }}
          >
            <img
              style={{ width: "80px", height: "80px", marginLeft: "80px" }}
              src={whiteLogo}
              alt={"logo"}
              loading="lazy"
            />
          </Typography>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {navItems.map((item: navItemsProps) => (
              <Button
                key={item.id}
                variant="text"
                sx={{ color: "#fff", marginLeft: "20px", marginRight: "20px" }}
                onClick={() => scrollToSection(item.id)}
              >
                <Typography variant="h5">{item.name}</Typography>
              </Button>
            ))}
            <ActionButtonsModule
              darkMode={props.darkMode}
              setDarkMode={props.setDarkMode}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box
        component="main"
        sx={{width: { xs: "100%", md: "80%" }, margin: "auto" }}
      >
        <div id="Home">
          <ImagesCarousel />
        </div>
        <div id="About">
          <AboutUs />
        </div>
        <br/>
        <div id="Our Projects">
          <ProjectsCarousel />
        </div>
        <br/>
        <div id="Contact">
          <Contact />
        </div>
      </Box>
    </Box>
  );
}
