import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Carousel from "react-material-ui-carousel";
import { Project } from "./Project";
import { useEffect, useState } from "react";

export type GitHubObject = {
  name: string;
  path: string;
  sha: string;
  size: number;
  url: string;
  html_url: string;
  git_url: string;
  download_url: string;
  type: "dir" | "file";
  _links: {
    self: string;
    git: string;
    html: string;
  };
};

const chunkArray = (array: GitHubObject[], chunkSize: number) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

export const ProjectsCarousel = () => {
  const { t } = useTranslation("global");
  const [projectFolders, setProjectFolders] = useState<GitHubObject[]>([]);
  const isSmallScreen = useMediaQuery((theme:any) => theme.breakpoints.down("sm"));
  const projectsPerRow = isSmallScreen ? 1 : 3;

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(
          `https://api.github.com/repos/montechserwis/strona-projekty/contents`
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        const folders = data.filter(
          (item: GitHubObject) => item.type === "dir"
        );
        setProjectFolders(folders);

      } catch (error) {
        console.error("Error fetching folders:", error);
      }
    };

    fetchImages();
  }, []);

  const projectsGroup = chunkArray(projectFolders, projectsPerRow);

  return (
    <>
      {projectFolders.length !== 0 ? (
        <>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h3" gutterBottom>
              {t("Our Projects")}
            </Typography>
          </Box>

          <Carousel autoPlay={false}>
            {projectsGroup.map((group, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "16px",
                }}
              >
                {group.map((folder: GitHubObject) => (
                  <Project key={folder.url} url={folder.url} name={folder.name}/>
                ))}
              </Box>
            ))}
          </Carousel>
        </>
      ) : null}
    </>
  );
};

export default ProjectsCarousel;