import { Box, Modal } from "@mui/material";
import Marella from "../../assets/projects/project_Marella/Marella.jpg";
import Carousel from "react-material-ui-carousel";
import { ImageItem, SliderImagesProps } from "../ImagesCarousel/ImagesCarousel";
import { useTranslation } from "react-i18next";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  "@media (max-width: 600px)": {
    width: "100%",
  },
};

type Props = {
  open: boolean;
  setOpen: (data: boolean) => void;
  images: SliderImagesProps[];
  name: string;
};

export const ProjectModal = (props: Props) => {
  const [t, i18n] = useTranslation("global");

  const projectModalImages =
    props.images?.map((image) => ({
      ...image,
    })) || [];

  const ProjectModalConfig: SliderImagesProps[] = [
    {
      id: 1,
      url: props.images?.length !== 0 ? props.images[0].url : Marella,
      content: (
        <div style={{padding:"10px"}}>
          <h2>{t(`${props.name}.title`)}</h2>
          <p>{t(`${props.name}.description`)}</p>
        </div>
      ),
    },
    ...projectModalImages,
  ];

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Carousel autoPlay={false}>
            {ProjectModalConfig.map((image: SliderImagesProps) => (
              <ImageItem key={image.id} item={image} />
            ))}
          </Carousel>
        </Box>
      </Modal>
    </div>
  );
};
