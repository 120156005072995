import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import Marella from "../../assets/projects/project_Marella/Marella.jpg";
import React, { useEffect, useState } from "react";
import { ProjectModal } from "./ProjectModal";
import { useTranslation } from "react-i18next";
import { GitHubObject } from "./ProjectsCarousel";
import { SliderImagesProps } from "../ImagesCarousel/ImagesCarousel";

type Props = {
  url: string;
  name: string;
};

const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + '...';
};

export const Project = (props: Props) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [t, i18n] = useTranslation("global");
  const [projectImages, setProjectImages] = useState<SliderImagesProps[]>([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(`${props.url}`);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const convertImages = data.map((image: GitHubObject) => {
          return {
            id: image.name,
            url: image.download_url,
          };
        });

        setProjectImages(convertImages);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);


  return (
    <>
      <ProjectModal
        open={openModal}
        setOpen={setOpenModal}
        images={projectImages}
        name={props.name}
      />
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          sx={{ height: 140 }}
          image={projectImages.length !== 0 ? projectImages[0].url : Marella}
          onClick={() => setOpenModal(true)}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          {truncateText(t(`${props.name}.title`), 50)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
          {truncateText(t(`${props.name}.description`), 75)}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setOpenModal(true)}>
            {t("Learn More")}
          </Button>
        </CardActions>
      </Card>
    </>
  );
};
