import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";
import CircleIcon from "@mui/icons-material/Circle";
import { useTranslation } from "react-i18next";

const TextItem = styled(Paper)(({ theme }) => ({
  width: "100%",
  height: "auto",
  padding: "40px",
  fontSize: "1rem",
}));

export const AboutUs = () => {
  const [t, i18n] = useTranslation("global");
  const theme = useTheme();

  const serviceList: string[] = [
    t("Certified welding"),
    t("Pipe installation"),
    t("GF plastic piping system"),
    t("Small to medium steel prefabrication"),
    t("General steelwork"),
    t("Specialty work in stainless steel and Aluminum"),
  ];

  return (
    <>
      <Box
        sx={{
          borderRadius: 2,
          m: { xs: 0, md: 8 },
          [theme.breakpoints.up("md")]: {
            m: 8,
          },
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h3" gutterBottom>
            {t("About")}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item>
            <TextItem elevation={6}>
              <Typography variant="body1">
                {t("Montech Serwis was founded")}
              </Typography>
              <br />
              <Typography variant="body1">
                {t("We are your full service marine contractor")}
              </Typography>
              <br />
              <Typography variant="body1">
                {t("Our skilled workers")}
              </Typography>
            </TextItem>
          </Grid>
          <Grid item>
            <TextItem elevation={6}>
              <Typography variant="h5" gutterBottom>
                {t("Our services")}
              </Typography>
              <Typography>{t("We provide services")} </Typography>
              <Typography>{t("Prefabication of modules")} </Typography>
              <List>
                {serviceList.map((service: string) => (
                  <ListItem key={service}>
                    <ListItemIcon>
                      <CircleIcon />
                    </ListItemIcon>
                    <ListItemText primary={service} />
                  </ListItem>
                ))}
              </List>
            </TextItem>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
