import { ThemeProvider, createTheme } from "@mui/material";
import React, { useEffect } from "react";
import DrawerAppBar from "./components/Appbar/Appbar";

function App() {
  const [darkMode, setDarkMode] = React.useState(() => {
    const storedDarkMode = localStorage.getItem("darkMode");
    return storedDarkMode ? JSON.parse(storedDarkMode) : false;
  });

  useEffect(() => {
    localStorage.setItem("darkMode", JSON.stringify(darkMode));
  }, [darkMode]);
  
  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <DrawerAppBar darkMode={darkMode} setDarkMode={setDarkMode} />
      </ThemeProvider>
    </>
  );
}

export default App;

// zmiana motywu strony
{
  /* <Button onClick={() => handleChangeLanguage("pl")}>PL</Button>
        <Button onClick={() => handleChangeLanguage("en")}>EN</Button>
        <Button onClick={toggleDarkMode}>
          {darkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}
        </Button>  */
}

// tłumaczenie
{
  /* <h1>{t("hello")}</h1>  */
}
