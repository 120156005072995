import { Box, Paper } from "@mui/material";
import { ReactNode } from "react";
import Carousel from "react-material-ui-carousel";
import CarouselBed from "../../assets/carousel/Carousel_Bed.jpg";
import CarouselPipes from "../../assets/carousel/Carousel_Pipes.png";
import CarouselWork from "../../assets/carousel/Carousel_Work.png";

export type SliderImagesProps = {
  id: number;
  url: string;
  content?: string | ReactNode;
};

export const ImagesCarousel = () => {
  const SliderImages: SliderImagesProps[] = [
    {
      id: 1,
      url: CarouselBed,
    },
    {
      id: 2,
      url: CarouselPipes,
    },
    {
      id: 3,
      url: CarouselWork,
    },
  ];
  return (
    <Carousel>
      {SliderImages.map((image: SliderImagesProps) => (
        <ImageItem key={image.id} item={image} />
      ))}
    </Carousel>
  );
};

export function ImageItem(props: { item: SliderImagesProps }) {
  const containerStyle = {
    position: "relative",
    height: "670px",
    width: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const imageStyle = {
    height: "670px",
    width: "100%",
    filter: props.item.content ? "brightness(30%)" : "",
  };

  const TextStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    "@media (max-width: 600px)": {
      width: "100%",
      top: "30%",
      left: "30%",
      transform: "translate(-30%, -30%)",
    },
  };

  return (
    <Paper sx={containerStyle}>
      <img
        src={props.item.url}
        alt={`photo-${props.item.id}`}
        style={imageStyle}
        loading="lazy"
      />
      <Box sx={TextStyle}>{props.item.content}</Box>
    </Paper>
  );
}
